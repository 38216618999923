import { UserDeliveryType } from "src/modules/delivery";
import { ServerCartDelivery } from "src/services/userDelivery/types";

const getDeliveryPresetId = (serverCartDelivery?: ServerCartDelivery | null): string => {
  switch (serverCartDelivery?.type) {
    case 'plan':
      return serverCartDelivery?.address.plan.id;
    case 'pickup':
      return  serverCartDelivery?.address.pickup.id;
    default:
      return '';
  }
};

export const getUserDeliveryPresetId = (userDelivery?: UserDeliveryType | null): string => {
  switch (userDelivery?.type) {
    case 'plan':
      return userDelivery?.id || '';
    case 'pickup':
      return  userDelivery?.id || '';
    default:
      return '';
  }
};

export default getDeliveryPresetId;
