import { useContext } from "react";
import UserProfileServiceContext from "./UserProfileServiceContext";

const useUserProfileService = () => {
  const context = useContext(UserProfileServiceContext);

  if (!context) {
    throw new Error('UserProfileService is used without provider');
  }

  return context;
};

export default useUserProfileService;
