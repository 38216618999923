import { useContext } from "react";
import UserDeliveryServiceContext from "./UserDeliveryServiceContext";

const useUserDeliveryService = () => {
  const context = useContext(UserDeliveryServiceContext);

  if (!context) {
    throw new Error('UserDeliveryServiceContext is used without provider');
  }

  return context;
};

export default useUserDeliveryService;
