import { ReactNode, useEffect } from 'react';
import { useUserDeliveryPresets } from 'src/hooks/useUserDeliveryPresets/useUserDeliveryPresets';
import { useDeliveryServices } from 'src/modules/delivery';
import useUserProfileService from 'src/services/userProfile/useUserProfileService';
import { useLocalize } from '../../../../services/localize/useLocalize';
import useUserDeliveryService from '../../../../services/userDelivery/useUserDeliveryService';
import { getDeliveryAddressText } from '../../helpers/getDeliveryAddressText';
import { getDeliveryTypeText } from '../../helpers/getDeliveryTypeText';
import useUserGeolocation from '../../hooks/useUserGeolocation/useUserGeolocation';
import addressManagementPreparerContext, { AddressManagementPreparerContext } from './addressManagementPreparerContext';
export interface Props {
  children: ReactNode;
  language: string;
}

export default function AddressManagementPreparerProvider(props: Props) {
  const { children, language } = props;
  const localize = useLocalize();
  const { deliveryServices, status: deliveryServicesStatus } = useDeliveryServices(language);
  const { userProfile } = useUserProfileService();
  const profileId = userProfile?.meta_user_id || '';
  const { deliveryPresets, deliveryPresetsAreFetching } = useUserDeliveryPresets(profileId);

  const { userDelivery, userDeliveryIsReady, updateUserDelivery } = useUserDeliveryService();
  const { geoIsAvailable, geoCoords, updateGeoCoords } = useUserGeolocation();

  useEffect(() => {
    if (userDeliveryIsReady && userDelivery === null) {
      updateGeoCoords();
    }
  }, [updateGeoCoords, userDeliveryIsReady, userDelivery]);

  const addressManagementIsReady = deliveryServicesStatus === 'succeeded' && userDeliveryIsReady && !deliveryPresetsAreFetching;

  const contextValue: AddressManagementPreparerContext = {
    addressManagementIsReady,
    deliveryServices,
    userDelivery,
    deliveryPresets,
    updateUserDelivery,
    geoCoords,
    geoIsAvailable,
    updateGeoCoords,
    amBarProps: {
      skeletonIsDisplayed: !addressManagementIsReady,
      deliveryTypeText: getDeliveryTypeText(userDelivery, localize),
      deliveryAddressText: getDeliveryAddressText(userDelivery, deliveryServices, localize),
    },
  };

  return (
    <addressManagementPreparerContext.Provider value={contextValue}>
      {children}
    </addressManagementPreparerContext.Provider>
  );
}
