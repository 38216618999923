import { fetcher } from "src/api/fetch";
import { FavoriteActionType } from "../types";

interface Params {
  actionType: FavoriteActionType;
  listIds: string[];
  eans: string[];
}

const requestPostFavoritesItems = async (params: Params): Promise<void> => {
  const {
    actionType,
    listIds,
    eans,
  } = params;

  const response = await fetcher('/user/lists/multi_items', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      items: eans.map(ean => ({ ean })),
      list_ids: {
        [actionType]: listIds,
      },
    }),
  });

  if (response.status === 'fail') {
    throw new Error('Post favorite lists item error');
  }
};

export default requestPostFavoritesItems;
