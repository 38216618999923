import { fetcher } from "src/api/fetch";
import { ShoppingList } from "src/services/favorites/types";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";

const requestGetFavoritesLists = async (language: string): Promise<ShoppingList[]> => {
  const response = await fetcher<ShoppingList[]>('/user/lists', {
    credentials: 'include',
    language,
  });

  if (response.status === 'fail') {
    throw new Error('Fetching lists error');
  }

  return getDataFromResponse<ShoppingList[]>(response, []);
};

export default requestGetFavoritesLists;
