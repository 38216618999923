import { ShoppingList } from "src/services/favorites/types";

export const FAVORITES_LISTS_KEY = 'favorites_lists';

export const setFavoritesListIdsToSessionStorage = (ids: string[]) => {
  window.sessionStorage.setItem(FAVORITES_LISTS_KEY, JSON.stringify(ids));
};

export const getFavoritesListIdsFromSessionStorage = (lists: ShoppingList[] | null): string[] => {
  const storageListsIds = window.sessionStorage?.getItem(FAVORITES_LISTS_KEY);
  const parsedStorageListsIds: string[] = storageListsIds ? JSON.parse(storageListsIds) : [];

  //filter list ids from session storage that unavailable to the current user
  const listIds = lists?.filter(l => parsedStorageListsIds.includes(l.id)).map(l => l.id) || [];
  const result = listIds.length > 0 ? listIds : ['default'];

  setFavoritesListIdsToSessionStorage(result);
  return result;
};

