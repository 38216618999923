import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import { getResultEmotionStyles } from '../StylishBox/helpers/getResultEmotionStyles';
import { StylishBoxStyles } from '../StylishBox/types';
import { useTheme } from '../theme';
import { Theme } from '../theme/types';
import getStyles from './getStyles';

type ButtonType = 'button' | 'submit' | 'reset';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'notAccentedGhost'
  | 'error';

export interface ButtonProps {
  children: ReactNode;
  dataMarker?: string;
  disabled?: boolean;
  tabIndex?: number;
  type?: ButtonType;
  variant: ButtonVariant;
  className?: string;
  width?: StylishBoxStyles['width'];
  sbs?: StylishBoxStyles;
  href?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  onClick?(e: React.MouseEvent): void;
}

type StyledButtonProps = Omit<ButtonProps, 'sbs' | 'width' | 'dataMarker'> & {
  sbs: StylishBoxStyles;
  theme: Theme;
  'aria-disabled'?: boolean;
  role?: string;
  'data-status'?: 'active' | 'inactive';
  'data-marker'?: string;
}

const StyledButton = styled('button', {
  shouldForwardProp: prop => isPropValid(prop)
    && prop !== 'variant'
    && prop !== 'theme'
    && prop !== 'sbs',
})(
  ({ variant, theme, sbs }: StyledButtonProps) => {
    const buttonStyles = getStyles(variant, theme);

    return [
      ...(sbs ? getResultEmotionStyles(sbs) : []),
      ...buttonStyles,
    ];
  },
);

const Button = (props: ButtonProps) => {
  const {
    children,
    href,
    target,
    dataMarker,
    disabled,
    variant,
    width,
    tabIndex,
    type,
    className: classNameProp,
    sbs: sbsProps = {},
    onClick,
  } = props;
  const isLink = !!href;

  const theme = useTheme();

  let buttonProps: StyledButtonProps = {
    children,
    'data-marker': dataMarker,
    className: classNameProp,
    tabIndex: tabIndex,
    variant,
    theme: theme,
    onClick(e: React.MouseEvent) {
      if (disabled) {
        e.preventDefault();
        return;
      }

      if (onClick) {
        onClick(e);
      }
    },
    sbs: {
      /* part of button styles can be overwritten by sbs prop */
      p: '0',
      lineHeight: '1.2',
      ...sbsProps,
      width: width ?? (sbsProps?.width || '100%'),
    },
  };

  if (isLink) {
    buttonProps = {
      ...buttonProps,
      href,
      target,
      'aria-disabled': disabled,
      role: 'button',
    };
  } else {
    buttonProps = {
      ...buttonProps,
      disabled,
      type,
      'data-status': disabled ? 'inactive' : 'active',
    };
  }

  return (
    <StyledButton
      as={isLink ? 'a' : 'button'}
      {...buttonProps}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
