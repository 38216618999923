import { NotificationService } from "src/services/notifications/types";
import { EanFavoriteListMap, ShoppingList } from "../types";
import determineListChanges from "./determineListChanges";
import requestPostFavoritesItems from "../ajax/requestPostFavoritesItems";
import { CatalogProductBase } from "src/api/types";
import { sendFavoriteEvent } from "./analytics";
import { ProductPlace } from "src/utils/enhancedEcommerce";

interface Params {
  product: CatalogProductBase;
  favoriteEansMap: EanFavoriteListMap;
  listId?: string;
  place: ProductPlace;
  lists: ShoppingList[] | null;
  onSuccess(): void;
  openFavoriteNotification: NotificationService['openFavoriteNotification'];
}

export const addOrRemoveItemFromLists = async (params: Params) => {
  const {
    listId = null,
    product,
    favoriteEansMap,
    lists,
    place,
    onSuccess,
    openFavoriteNotification,
  } = params;

  const {
    listIds,
    actionType,
    eans,
  } = determineListChanges({ listId, favoriteEansMap, product, lists });

  await requestPostFavoritesItems({
    eans,
    actionType,
    listIds,
  });

  onSuccess();

  sendFavoriteEvent({ actionType, place });
  openFavoriteNotification({
    product,
    listIds,
    actionType,
  });
};
