import { sendAnalyticEvent } from 'src/utils/analytics/sendAnalyticEvent';
import { Delivery } from '../../delivery/types';

export const sendDeliveryAddressPopupShow = (place: 'main' | 'drawer') => {
  sendAnalyticEvent({
    event: 'delivery_address_popup_show',
    place,
  });
};

export const sendSelectOfDeliveryAddress = (
  deliveryType: Delivery['type'],
  inputType: 'input' | 'pin',
) => {
  sendAnalyticEvent({
    event: 'select_of_delivery_address',
    delivery_type: deliveryType,
    address_input_type: inputType,
  });
};

export const sendDeliveryAddressSelected = (deliveryType: Delivery['type']) => {
  sendAnalyticEvent({
    event: 'delivery_address_selected',
    delivery_type: deliveryType,
  });
};

export const sendAddressInputError = () => {
  sendAnalyticEvent({
    event: 'address_input_error',
    delivery_type: 'plan',
    error_type: 'address_not_found',
  });
};

export const sendCorrectAddress = (place: 'address_card' | 'address_input') => {
  sendAnalyticEvent({
    event: 'correct_address',
    place,
  });
};

export const sendAddressCorrected = () => {
  sendAnalyticEvent({
    event: 'address_corrected',
  });
};

export const sendFindUserOnMap = (
  deliveryType: Delivery['type'],
  results: 'geolocation_blocked' | 'address_found' | 'address_not_found' | 'success',
) => {
  sendAnalyticEvent({
    event: 'find_user_on_map',
    delivery_type: deliveryType,
    results,
  });
};

export const sendSelectedAddress = () => {
  sendAnalyticEvent({
    'event': 'delivery_address_selected',
    'delivery_type': 'nova_poshta_address',
  });
};
