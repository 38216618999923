import { sendAnalyticEvent } from "src/utils/analytics/sendAnalyticEvent";
import { FavoriteActionType } from "../types";
import { ProductPlace } from "src/utils/enhancedEcommerce";


interface Params {
  actionType: FavoriteActionType;
  place: ProductPlace;
}

export const sendFavoriteEvent = ({ actionType, place }: Params) => {
  sendAnalyticEvent({
    event: "add_to_wishlist",
    action: actionType === 'add' ? "add_to_list" : 'remove_from_list',
    place,
  });
};
