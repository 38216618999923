import { createContext, ReactNode, useContext } from 'react';
import { Category } from 'src/api/types';
import AuthServiceContext from './auth/AuthServiceContext';
import { AuthService } from './auth/types';
import useAuthLogic from './auth/useAuthLogic';
import useCategoriesService from './categories/useCategoriesService';
import FavoritesServiceContext from './favorites/FavoritesContext';
import useLangService from './language/useLangService';
import { LocalizeFunc, useLocalizeService } from './localize/useLocalizeService';
import ModalsServiceContext from './modals/ModalsServiceContext';
import useModalsLogic from './modals/useModalsLogic';
import NotificationServiceContext from './notifications/NotificationsServiceContext';
import useNotificationsLogic from './notifications/useNotificationsLogic';
import UserDeliveryServiceContext from './userDelivery/UserDeliveryServiceContext';
import useUserDeliveryLogic from './userDelivery/useUserDeliveryLogic';
import UserProfileServiceContext from './userProfile/UserProfileServiceContext';
import useUserProfileLogic from './userProfile/useUserProfileLogic';
import useFavoritesLogic from './favorites/useFavoritesLogic';

export interface GlobalServices {
  language: string;
  localize: LocalizeFunc;
  categories: Category[];
}

interface ServicesProviderProps {
  children: ReactNode;
  initLang: string;
  translations: any;
  initCategories: Category[];
}

export const ServicesContext = createContext<GlobalServices>(null as any);

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = ({
  children,
  initLang,
  translations,
  initCategories,
}: ServicesProviderProps) => {

  const language = useLangService(initLang);
  const localize = useLocalizeService({ translations });
  const categories = useCategoriesService(initCategories);

  const authLogic = useAuthLogic(language);
  const isLoggedIn = authLogic.isLoggedIn;
  const userDelivery = useUserDeliveryLogic(language, isLoggedIn);
  const modals = useModalsLogic();
  const userProfile = useUserProfileLogic(language, isLoggedIn);
  const notifications = useNotificationsLogic();
  const favorites = useFavoritesLogic({
    language,
    isLoggedIn,
    openModal: modals.openModal,
    openFavoriteNotification: notifications.openFavoriteNotification,
  });

  const auth: AuthService = {
    ...authLogic,
    logout: async () => {
      await authLogic.logout();
      userProfile.clearProfile();
      userDelivery.clearUserDelivery();
      favorites.clearLists();
    },
  };

  const contextValue: GlobalServices = {
    language,
    localize,
    categories,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      <ModalsServiceContext.Provider value={modals}>
        <AuthServiceContext.Provider value={auth}>
          <UserDeliveryServiceContext.Provider value={userDelivery}>
            <UserProfileServiceContext.Provider value={userProfile}>
              <FavoritesServiceContext.Provider value={favorites}>
                <NotificationServiceContext.Provider value={notifications}>
                  {children}
                </NotificationServiceContext.Provider>
              </FavoritesServiceContext.Provider>
            </UserProfileServiceContext.Provider>
          </UserDeliveryServiceContext.Provider>
        </AuthServiceContext.Provider>
      </ModalsServiceContext.Provider>
    </ServicesContext.Provider>
  );
};
