import { CatalogProductBase } from "src/api/types";
import { EanFavoriteListMap, FavoriteActionType, ShoppingList } from "src/services/favorites/types";
import { getFavoritesListIdsFromSessionStorage } from "./sessionStorageHelpers";

interface GetAddingRemovingListsParams {
  product: CatalogProductBase;
  favoriteEansMap: EanFavoriteListMap;
  listId: null | string;
  lists: ShoppingList[] | null;
}

const determineListChanges = ({
  product,
  favoriteEansMap,
  listId,
  lists,
}: GetAddingRemovingListsParams) => {
  const {
    ean: mainEan, //the main EAN is only used for adding to the list
    eans: allEans, //we use all EANs to remove duplicate products from different chains
  } = product;

  const removingListsForFavoritePage = listId && allEans.some(ean => favoriteEansMap[ean]?.includes(listId)) ? [listId] : [];

  const removingLists = listId
    ? removingListsForFavoritePage //for favorite list page
    : allEans.flatMap(ean => favoriteEansMap[ean] || []); // for other pages

  const favoriteIdsFromStorage = getFavoritesListIdsFromSessionStorage(lists);
  const addingLists = listId ? [listId] : favoriteIdsFromStorage;
  const isRemoving = removingLists.length > 0;

  const actionType: FavoriteActionType = isRemoving ? 'remove' : 'add';
  const listIds = isRemoving ? removingLists : addingLists;
  const eans = isRemoving ? allEans : [mainEan];

  return { listIds, actionType, eans };
};

export default determineListChanges;
