import { useState } from "react";
import { FavoriteNotificationData, NotificationState } from "./types";

const useNotificationsLogic = () => {
  const [notification, setNotification] = useState<NotificationState>({
    type: null,
    messageContent: null,
    data: null,
  });

  const closeNotification = () => {
    setNotification({
      type: null,
      messageContent: null,
      data: null,
    });
  };

  const openFavoriteNotification = (data: FavoriteNotificationData) => {
    setNotification({
      type: 'favorite',
      messageContent: null,
      data,
    });
  };

  return {
    state: notification,
    closeNotification,
    openFavoriteNotification,
  };
};

export default useNotificationsLogic;
