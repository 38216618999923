import { useState } from 'react';
import { ModalsApi, ModalsState } from './types';


const initialModalsState: ModalsState = {
  addressManagement: { isOpen: false },
  retailsDrawerModal: { isOpen: false },
  authorizationModal: { isOpen: false },
  forgotLoginModal: { isOpen: false },
  registrationSuccessModal: { isOpen: false },
  addToListModal: { isOpen: false },
  createListModal: { isOpen: false },
};

const useModalsLogic = () => {
  const [modalsState, setModalsState] = useState(initialModalsState);

  const handleOpenModal: ModalsApi['openModal'] = (modalName, data) => {
    setModalsState((state) => ({
      ...state,
      [modalName]: { isOpen: true, data },
    }));
  };

  const handleCloseModal: ModalsApi['closeModal'] = (modalName) => {
    setModalsState((state) => ({
      ...state,
      [modalName]: { isOpen: false },
    }));
  };

  const handleSwitchModal: ModalsApi['switchModal'] = (prevModalName, nextModal) => {
    setModalsState((state) => ({
      ...state,
      [prevModalName]: { isOpen: false },
      [nextModal.name]: { isOpen: true, data: nextModal.data },
    }));
  };

  return {
    state: modalsState,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
    switchModal: handleSwitchModal,
  };
};

export default useModalsLogic;
