import { useCallback, useEffect, useMemo, useState } from "react";
import { CatalogProductBase } from "src/api/types";
import { useSessionStorage } from "usehooks-ts";
import { ModalsApi } from "../modals/types";
import { NotificationService } from "../notifications/types";
import requestGetFavoritesLists from "./ajax/requestGetFavoritesLists";
import { addOrRemoveItemFromLists } from "./helpers/addOrRemoveItemFromLists";
import { createEanListMap } from "./helpers/createEanListMap";
import { ShoppingList } from "./types";
import { ProductPlace } from "src/utils/enhancedEcommerce";

interface FavoriteItemParams {
  product: CatalogProductBase;
  listId?: string;
  place: ProductPlace;
}

type GetProductIsFavoriteFunc = (eans: string[], listId?: string) => boolean;

export interface FavoritesService {
  lists: ShoppingList[] | null;
  listsIsFetching: boolean;
  revalidateFavoriteLists(): Promise<void>;
  toggleFavorite(params: FavoriteItemParams): void;
  getProductIsFavorite: GetProductIsFavoriteFunc;
  clearLists(): void;
}

interface Params {
  openFavoriteNotification: NotificationService['openFavoriteNotification'];
  openModal: ModalsApi['openModal'];
  isLoggedIn: boolean;
  language: string;
}

const useFavoritesLogic = (params: Params): FavoritesService => {
  const {
    openModal,
    openFavoriteNotification,
    isLoggedIn,
    language,
  } = params;
  const [isFetching, setIsFetching] = useState(false);
  const [lists, setLists] = useState<ShoppingList[] | null>(null);
  const [
    favoriteItemParamsFromStorage,
    setFavoriteItemParamsToStorage,
  ] = useSessionStorage<FavoriteItemParams | null>('favoriteParams', null);

  const favoriteEansMap = useMemo(() => lists ? createEanListMap(lists) : {}, [lists]);

  const getFavoritesLists = useCallback(async () => {
    setIsFetching(true);
    const response = await requestGetFavoritesLists(language);

    setLists(response);
    setIsFetching(false);
  }, [language]);

  const getProductIsFavorite = useCallback<GetProductIsFavoriteFunc>((eans, listId) => {
    return eans.some(ean => listId
      ? favoriteEansMap[ean]?.includes(listId)
      : !!favoriteEansMap[ean],
    );
  }, [favoriteEansMap]);

  const toggleFavorite = async (favoriteItemParams: FavoriteItemParams) => {
    if (isLoggedIn) {
      addOrRemoveItemFromLists({
        ...favoriteItemParams,
        lists,
        favoriteEansMap,
        onSuccess: getFavoritesLists,
        openFavoriteNotification,
      });
    } else {
      openModal('authorizationModal');
      setFavoriteItemParamsToStorage(favoriteItemParams);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getFavoritesLists();
    }
  }, [getFavoritesLists, isLoggedIn]);

  useEffect(() => {
    //add product to list after login if product exists in storage
    const listsAreReady = lists && lists.length > 0;
    const dataAreReady = isLoggedIn
      && favoriteItemParamsFromStorage
      && listsAreReady;

    if (dataAreReady) {
      const ean = favoriteItemParamsFromStorage.product.ean;
      const productIsNotInLists = !favoriteEansMap[ean];

      if (productIsNotInLists) {
        addOrRemoveItemFromLists({
          ...favoriteItemParamsFromStorage,
          lists,
          favoriteEansMap,
          onSuccess: getFavoritesLists,
          openFavoriteNotification,
        });
      }

      setFavoriteItemParamsToStorage(null);
    }
  }, [
    favoriteEansMap,
    favoriteItemParamsFromStorage,
    isLoggedIn,
    lists,
    getFavoritesLists,
    setFavoriteItemParamsToStorage,
    openFavoriteNotification,
    getProductIsFavorite,
  ]);

  return {
    lists,
    listsIsFetching: isFetching,
    toggleFavorite,
    revalidateFavoriteLists: getFavoritesLists,
    getProductIsFavorite,
    clearLists: () => setLists(null),
  };
};

export default useFavoritesLogic;
