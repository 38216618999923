/* istanbul ignore file */

import { ReactNode } from 'react';
import useLang from '../../../services/language/useLang';
import { useLocalize } from '../../../services/localize/useLocalize';
import useUserDeliveryService from '../../../services/userDelivery/useUserDeliveryService';
import RetailsInfoContext from './RetailsInfoContext';
import useRetailsInfoLogic from './useRetailsInfoLogic';

interface RetailsInfoProviderProps {
  children: ReactNode;
}

const RetailsInfoProvider = (props: RetailsInfoProviderProps) => {
  const { children } = props;
  const language = useLang();
  const localize = useLocalize();
  const { userDelivery, userDeliveryIsReady } = useUserDeliveryService();

  const ctxValue = useRetailsInfoLogic({
    userDelivery,
    userDeliveryIsReady,
    language,
    localize,
  });

  return (
    <RetailsInfoContext.Provider value={ ctxValue }>
      { children }
    </RetailsInfoContext.Provider>
  );
};

export default RetailsInfoProvider;
