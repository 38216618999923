import { AnalyticEvent } from "global";

export const sendAnalyticEvent = (event: AnalyticEvent) => {
  if (!window) {
    return;
  }
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(event);
};
