import { fetcher } from "src/api/fetch";
import useLang from "src/services/language/useLang";
import { ServerCartDelivery } from "src/services/userDelivery/types";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import useSWR from "swr";

export const deliveryPresetsUrl = `/user/delivery_presets`;

export function useUserDeliveryPresets(profileId: string) {
  const language = useLang();
  const args = !!profileId ? ['delivery_presets', profileId] : null;

  const swrFetcher = async () =>{
    const res = await fetcher<ServerCartDelivery[]>(deliveryPresetsUrl, {
      credentials: 'include',
      language,
    });
    return getDataFromResponse(res, []);
  };

  const { data, error, mutate } = useSWR<ServerCartDelivery[]>(args, swrFetcher);

  const deliveryPresetsAreFetching = !!profileId ? !data && !error : false;

  return {
    deliveryPresets: data || [],
    deliveryPresetsAreFetching,
    deliveryPresetsMutate: mutate,
  };
}
