import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import convertDateToString from '../../../utils/date/convertDateToString';
import { Delivery } from '../../delivery/types';
import { SimpleTimeWindow } from './getRetailInfo';

const convertTimeWindowToString = (
  timeWindow: SimpleTimeWindow,
  deliveryType: Delivery['type'],
  localize: LocalizeFunc,
) => {
  const firstPart = deliveryType === 'pickup'
    ? localize('slot_text_first_part_pickup')
    : localize('slot_text_first_part_deliver');

  const datePart = convertDateToString(new Date(timeWindow.date), localize);

  const [timeFrom, timeTo] = timeWindow.time_range.split(' - ');
  let timePart = `${localize('slot_text_from')} ${timeFrom}`;

  if ( timeTo ) {
    timePart += ` ${localize('slot_text_to')} ${timeTo}`;
  }

  return `${firstPart} ${datePart} ${timePart}`;
};

export default convertTimeWindowToString;
