import { sendAnalyticEvent } from "src/utils/analytics/sendAnalyticEvent";

export const sendChainCardClick = (
  place: 'main' | 'drawer',
  isAvailable: boolean,
) => {
  sendAnalyticEvent({
    event: 'chain_card_click',
    place,
    shipping_available: isAvailable.toString(),
  });
};

export const sendChainCardShown = (
  totalRetailsCount: number,
  availableRetailsCount: number,
) => {
  sendAnalyticEvent({
    event: 'chain_cards_shown',
    total_chain_cards: totalRetailsCount,
    delivery_available_cards: availableRetailsCount,
  });
};
